<template>
  <container>
    <div class="d-flex flex-column">
      <dx-grid-with-search
        title="ICD Rules"
        :dataSource="icdRules"
        :columns="columns"
        noDataText="No icd rules"
        :toolbar="toolbar"
      >
        <template v-slot:extraActions>
          <add-button
            @click="handleClickAdd"
            class="align-self-end"
            :icon="isAdding ? 'times' : 'plus'"
            :text="isAdding ? 'Close' : 'Add'"
            v-if="permissions.IcdRuleCreateEdit"
          />
        </template>
        <template v-slot:actions="{ data: { rowIndex, data } }">
          <div>
            <icon-button
              v-if="permissions.IcdRuleCreateEdit"
              @click="handleEditRule(data)"
              class="btn p-0 mx-2 text-primary"
              icon="pen-alt"
              :data-testid="`edit-${rowIndex}`"
            />
            <icon-button
              v-if="permissions.IcdRuleDelete"
              @click="handleRemoveRule(data)"
              class="btn p-0 mx-2 text-danger"
              icon="trash-alt"
              :data-testid="`delete-${rowIndex}`"
            />
          </div>
        </template>
        <template v-slot:expirationCell="{ data }">
          <expiry-template :expiry-date="data.value" />
        </template>
      </dx-grid-with-search>
    </div>
    <div class="mt-5">
      <ICDRulesForm
        v-if="isAdding || isEditing"
        :icdRuleId="selectedICDRule"
        @submit="handleSubmitRule"
        @cancel="handleCancel"
        @original="cloneOriginal"
      />
    </div>
  </container>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapState, mapGetters } from "vuex";
import DropdownApi from "@/services/dropdown.js";
import IcdRulesApi from "@/services/IcdRules.js";
import ICDRulesForm from "@/components/forms/CodeMaintenance/ICDRules/ICDRulesForm.vue";
import auditLog from "../../services/AuditLog";
import { createLogItem } from "../../modules/helpers";
import DxGridWithSearch from "../common/DxGridWithSearch.vue";
import ExpiryTemplate from "../common/ExpiryTemplate.vue";
import Container from "../common/Container.vue";
import IconButton from "../common/IconButton.vue";
import AddButton from "../common/AddButton.vue";

export default {
  name: "ICD_Mapping",
  metaInfo: {
    title: "ICD Rules",
    titleTemplate: "IntelliPath - %s"
  },
  components: {
    ICDRulesForm,
    DxGridWithSearch,
    ExpiryTemplate,
    Container,
    IconButton,
    AddButton
  },
  data() {
    return {
      toolbar: {
        items: [
          {
            location: "after",
            template: "extraActions"
          }
        ]
      },
      isAdding: false,
      isEditing: false,
      availableLabs: [],
      selectedICDRule: null,
      originalIcdRule: {},
      nonBillableCodes: [],
      icdRules: [],
      defaultICDRule: {
        icdCode: null,
        mismatchOption: null,
        replacePreviousStringWith: "",
        useDigit: "",
        useDigitWarning: false,
        formulas: []
      }
    };
  },

  mounted() {
    auditLog.insertLogMessage({
      ...createLogItem({}, 7),
      comments: "Visited Admin Icd Rules page."
    });
  },

  computed: {
    ...mapState(["currentUser"]),
    ...mapGetters(["permissions"]),

    columns() {
      return [
        {
          dataField: "name",
          sortOrder: "asc",
          sortIndex: 1
        },
        {
          dataField: "description",
          caption: "Description"
        },
        { dataField: "effectiveOn", dataType: "date" },
        { dataField: "expiryOn", dataType: "date", cellTemplate: "expirationCell" },
        { type: "buttons", cellTemplate: "actions" }
      ];
    }
  },
  created() {
    this.loadAllRules();
  },
  methods: {
    handleSubmitRule(response) {
      const { validationErrors } = response;
      if (validationErrors.length) {
        alert(validationErrors.join("\n"));
      } else {
        this.isAdding = false;
        this.isEditing = false;
        this.selectedICDRule = null;
        return this.loadAllRules();
      }
    },
    cloneOriginal(data) {
      this.originalIcdRule = cloneDeep(data);
    },
    loadAllRules() {
      return IcdRulesApi.getIcdRules().then(res => {
        this.icdRules = res || [];
      });
    },
    async handleClickAdd() {
      if (this.isEditing || this.isAdding) {
        const confirm = await window.confirm(
          "You may have unsaved data.\nAre you sure you want to continue?"
        );
        if (!confirm) {
          return;
        }
        this.isEditing = false;
      }
      this.selectedICDRule = null;
      this.isAdding = !this.isAdding;
      return;
    },
    fetchIcds(options) {
      return DropdownApi.searchICDCodes(options);
    },
    async handleCancel() {
      const confirm = await window.confirm(
        "Warning, you may have unsaved data\n Are you sure you want to cancel?"
      );
      if (confirm) {
        this.isEditing = false;
        this.isAdding = false;
        this.selectedICDRule = null;
      }
    },
    async handleEditRule(data) {
      if (this.isEditing || this.isAdding) {
        const confirm = await window.confirm(
          "Warning, you may have unsaved data\n Are you sure you want to cancel?"
        );
        if (!confirm) {
          return;
        }
      }
      if (data?.id != undefined) {
        this.selectedICDRule = data.id;
        this.isEditing = true;
        this.isAdding = false;
      }
    },
    async handleRemoveRule(data) {
      const dialogueResponse = await confirm(
        "This action is irreversible\nAre you sure you want to continue?",
        "Remove icd rule"
      );

      if (dialogueResponse) {
        ///Code for removal
        const icdRule = data;
        return IcdRulesApi.removeIcdRule(icdRule.id).then(this.loadAllRules);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
