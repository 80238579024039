<template>
  <div>
    <form
      @scroll.stop.prevent
      @submit.prevent="handleSubmit(icdRule)"
      class="container main_wrapper p-4 border"
      v-shortkey="saveShortkey"
      @shortkey="handleSubmit(icdRule)"
    >
      <div class="row mb-3 align-items-start">
        <text-input
          label="Name"
          name="name"
          id="name"
          class="col"
          v-model="icdRule.name"
          :validator="$v.icdRule.name"
        />
      </div>
      <div class="row mb-2">
        <text-area-input
          class="col"
          name="description"
          id="description"
          label="Description"
          v-model="icdRule.description"
          :resize="false"
          rows="4"
          cols="25"
          :validator="$v.icdRule.description"
        />
        <div class="col">
          <date-picker
            id="effectiveOn"
            name="effectiveOn"
            v-model="icdRule.effectiveOn"
            label="Effective On"
            :validator="$v.icdRule.effectiveOn"
          />
          <date-picker
            name="expiryOn"
            v-model="icdRule.expiryOn"
            label="Expires On"
            :validator="$v.icdRule.expiryOn"
          />
        </div>
      </div>

      <div class="row justify-content-between">
        <div class="col d-flex flex-column">
          <div class="d-flex align-self-end">
            <icon-button
              type="button"
              icon="trash-alt"
              @click="handleDeleteSelected"
              class="btn btn-outline-danger mr-2"
            >
              Delete
            </icon-button>
            <add-button @click="handleAddFormula" type="button" />
          </div>
          <!-- TODO TRUNCATE WORDS FOR BETTER UI -->
          <prop-table
            :rowDragging="rowDrag"
            :apiRemove="true"
            class="w-100 mt-1"
            data-testid="formulaTable"
            v-model="icdFormulas"
            :columns="formulaTableColumns"
            :selection="selection"
            :scrolling="scrolling"
            :wordWrapEnabled="true"
            @remove="handleRemoveFormula"
            @edit="handleEdit"
            @update:selectedRowKeys="handleSelect"
          />
        </div>
        <div class="col-5 d-flex flex-column fieldset border py-2 mt-5 px-3">
          <h6><b>If no match</b></h6>
          <fieldset class="">
            <div class="d-flex align-items-center">
              <input
                value="0"
                v-model="icdRule.mismatchOptionId"
                name="mismatchOptionId"
                type="radio"
                id="stopWithPrevious"
              />
              <label for="stopWithPrevious" class="my-0 ml-2"><b>Stop with Previous</b></label>
            </div>

            <div class="d-flex align-items-center mt-1">
              <input
                id="replaceWithPattern"
                value="1"
                name="mismatchOptionId"
                v-model="icdRule.mismatchOptionId"
                type="radio"
              />
              <label for="replaceWithPattern" class="my-0 ml-2"
                ><b>Replace With Pattern: </b></label
              >
              <text-input
                :noLabel="true"
                name="mismatchOptionId"
                class="ml-2 w-50 align-self-end"
                v-if="icdRule.mismatchOptionId === 1 || icdRule.mismatchOptionId === '1'"
                :validator="$v.icdRule.replacePreviousWith"
                v-model="icdRule.replacePreviousWith"
              />
            </div>
            <div class="d-flex align-items-center my-1">
              <input
                id="useDigit"
                value="2"
                name="mismatchOptionId"
                v-model="icdRule.mismatchOptionId"
                type="radio"
              />
              <label for="useDigit" class="my-0 ml-2"><b>Use Digit:</b></label>
              <div
                class="d-flex w-75 align-items-center"
                v-if="icdRule.mismatchOptionId === 2 || icdRule.mismatchOptionId === '2'"
              >
                <text-input
                  :noLabel="true"
                  :validator="$v.icdRule.useDigit"
                  class="w-50 mx-2 align-self-end"
                  v-model="icdRule.useDigit"
                  @keydown.69.prevent
                />
                <input type="checkbox" name="useDigitWarning" v-model="icdRule.useDigitWarning" />

                <label for="useDigitWarning" class="ck-button mx-2 mt-1"><b>Warning</b></label>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <input
                value="3"
                name="mismatchOptionId"
                id="warning"
                v-model="icdRule.mismatchOptionId"
                type="radio"
              />
              <label for="warning" class="my-0 ml-2"><b>Warning</b></label>
            </div>
          </fieldset>
          <div v-if="$v.icdRule.mismatchOptionId.$invalid">
            <div
              class="validation-error"
              v-for="(key, index) in Object.keys($v.icdRule.mismatchOptionId.$params)"
              :key="index"
            >
              <span class="small text-danger mb-2 error" v-if="!$v.icdRule.mismatchOptionId[key]">
                {{ validatorMsgMap[key] }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-end mt-3">
        <button type="button" @click="$emit('cancel')" class="btn btn-danger">Cancel</button>
        <button :disabled="$v.icdRule.$invalid" type="submit" class="btn mx-2 btn-primary">
          Save
        </button>
      </div>
    </form>
    <modal data-testid="formulaPopup" :status="isFormulaOpen" @close="handleFormulaClose">
      <formula-form
        :selectedFormula="selectedFormula"
        @close="handleFormulaClose"
        @submit="handleSubmitFormula"
      />
    </modal>
  </div>
</template>

<script>
import PropTable from "@/components/common/PropTable.vue";
import { required, helpers } from "vuelidate/lib/validators";
import FormulaForm from "./FormulaForm.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import Modal from "@/components/common/Modal.vue";
import TextAreaInput from "@/components/TextAreaInput.vue";
import IcdRulesApi from "@/services/IcdRules.js";
import { altKey, createLogComment, createLogItem, validatorMsgMapBase } from "@/modules/helpers.js";
import { afterEffective, effectiveMinValue } from "@/modules/helpers";
import TextInput from "@/components/common/TextInput.vue";
import AddButton from "@/components/common/AddButton.vue";
import { AuditLogApi } from "@/services";
import { cloneDeep, unset } from "lodash";
import IconButton from "@/components/common/IconButton.vue";

export default {
  props: {
    icdRuleId: {
      required: true
    }
  },
  name: "Icd10RulesForm",
  components: {
    PropTable,
    DatePicker,
    Modal,
    FormulaForm,
    TextAreaInput,
    TextInput,
    AddButton,
    IconButton
  },
  created() {
    if (this.icdRuleId) {
      IcdRulesApi.getIcdRuleById(this.icdRuleId).then(icdRule => {
        this.originalIcdRule = cloneDeep(icdRule);
        return (this.icdRule = { ...icdRule });
      });
    } else {
      this.icdRule = { ...this.defaultICDRule };
    }
  },
  data() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return {
      rowDrag: {
        allowReordering: true,
        onReorder: this.handleReorder,
        dropFeedbackMode: "push"
      },
      scrolling: {
        mode: "infinite",
        useNative: true,
        showScrollbar: "always"
      },
      originalIcdRule: {},
      selection: {
        allowSelectAll: true,
        mode: "multiple",
        showCheckBoxesMode: "always"
      },
      selectedFormulaIndex: null,
      selectedFormula: null,
      formulaTableColumns: [
        {
          dataField: "value",
          dataType: "number",
          alignment: "center",
          allowSorting: false,
          width: "25%"
        },
        {
          dataField: "icdWordsCsv",
          dataType: "string",
          caption: "Word Match/Formula",
          allowSorting: false,
          width: "350px"
        },
        {
          dataField: "sortOrder",
          sortOrder: "asc",
          sortIndex: 0,
          visible: false
        }
      ],
      isFormulaOpen: false,
      isAdding: false,
      isEditing: false,
      selectedRules: [],
      icdRule: {
        name: "",
        description: "",
        mismatchOptionId: null,
        matchOption: "",
        replacePreviousStringWith: "",
        useDigit: "",
        useDigitWarning: false,
        icdFormulas: [],
        effectiveOn: today,
        expiryOn: null
      },
      defaultICDRule: {
        name: "",
        description: "",
        mismatchOptionId: null,
        matchOption: "",
        replacePreviousWith: "",
        useDigit: "",
        useDigitWarning: false,
        icdFormulas: [],
        effectiveOn: today,
        expiryOn: null
      },
      saveShortkey: altKey("s")
    };
  },
  validations: {
    icdRule: {
      name: {
        required
      },
      description: {
        required
      },
      mismatchOptionId: {
        required
      },
      effectiveOn: {
        minValue: effectiveMinValue,
        required
      },
      expiryOn: {
        afterEffective
      },
      replacePreviousStringWith: {
        required(val, vm) {
          if (vm.matchOption === "1" || vm.matchOption === 1) {
            return helpers.req(val);
          }
          return true;
        }
      },
      useDigit: {
        required(val, vm) {
          if (vm.matchOption === "5" || vm.matchOption === 5) {
            return helpers.req(val);
          }
          return true;
        },
        alphaNum(val) {
          return helpers.regex(val, /[0-9x]/i);
        }
      }
    }
  },
  methods: {
    async handleSubmit(data) {
      this.$v.icdRule.$touch();
      if (this.$v.icdRule.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      data.icdFormulas.forEach(item => {
        if (item.isNew) {
          unset(item, "id");
        }
      });

      try {
        const hasChanges = JSON.stringify(data) !== JSON.stringify(this.originalIcdRule);
        let response = { validationErrors: [] };
        if (data.id && hasChanges) {
          const logItem = createLogItem({}, 5);
          logItem.comments = createLogComment(this.originalIcdRule, data);
          const formulaChanges = this.originalIcdRule.icdFormulas
            .map((formula, idx) => {
              const updatedFormula = data.icdFormulas[idx];
              if (updatedFormula) {
                return createLogComment(formula, updatedFormula);
              } else {
                return null;
              }
            })
            .filter(e => e);
          if (formulaChanges) {
            logItem.comments += `\n icdFormulas: [${formulaChanges}]`;
          }
          logItem.comments = `${data.name}: ${logItem.comments}`;
          await AuditLogApi.insertLogMessage(logItem);
          response = await IcdRulesApi.updateIcdRule(data);
        } else {
          const logItem = createLogItem({}, 4);
          logItem.comments = `Added a new icd rule ${data.name}.`;
          await AuditLogApi.insertLogMessage(logItem);
          //API DISPATCH
          response = await IcdRulesApi.insertIcdRule(data);
        }

        this.$emit("submit", response);
      } catch (error) {
        window.notify("Error occured", "error");
      }
    },
    handleSelect(data) {
      this.selectedRows = data;
    },
    handleDeleteSelected() {
      this.selectedRows.forEach(e => {
        if (e.id) {
          e.isDeleted = true;
        } else {
          const index = this.icdRule.icdFormulas.findIndex(formula => {
            return e.value === formula.value && e.icdWordsCsv === formula.icdWordsCsv;
          });
          this.icdRule.icdFormulas.splice(index, 1);
        }
      });
    },
    handleEdit({ rowIndex, data }) {
      this.selectedFormulaIndex = rowIndex;
      this.selectedFormula = data;
      this.isFormulaOpen = true;
      this.isEditing = true;
      this.isAdding = false;
    },
    handleRemoveFormula({ data, rowIndex }) {
      const formula = { ...data };
      if (data.id != undefined) {
        data.isDeleted = true;
      } else {
        this.icdRule.icdFormulas
          .sort((a, b) => {
            if (a.sortOrder > b.sortOrder) {
              return 1;
            } else {
              return -1;
            }
          })
          .splice(rowIndex, 1);
      }
      /* AFTER DELETING SAID ICDFORMULA WE MUST
      GO THROUGH THE LIST AND REDUCE THE
      SORTORDER INTEGER ON EACH ICDFORMULA WHO'S
      SORT ORDER IS HIGHER THAN THE ONE DELETED */
      return this.icdRule.icdFormulas.forEach(e => {
        if (e.sortOrder > formula.sortOrder) {
          e.sortOrder--;
        }
      });
    },
    handleReorder(e) {
      const visibleRows = e.component.getVisibleRows();
      const toIndex = this.icdRule.icdFormulas.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = this.icdRule.icdFormulas.indexOf(e.itemData);
      const newFormulas = [...this.icdRule.icdFormulas];

      newFormulas.splice(fromIndex, 1);
      newFormulas.splice(toIndex, 0, e.itemData);
      newFormulas.forEach(formula => {
        if (!formula.isDeleted) {
          formula.sortOrder = newFormulas.reduce((acc, curr) => {
            if (!curr.isDeleted && curr.sortOrder > acc) {
              return curr.sortOrder + 1;
            } else {
              return acc + 1;
            }
          }, 0);
        }
      });
      this.icdRule.icdFormulas = newFormulas;

      e.component.refresh(true);
    },
    handleAddFormula() {
      if (this.isEditing) {
        return;
      }
      this.isEditing = false;
      this.selectedFormula = null;
      this.selectedFormulaIndex = null;
      this.isAdding = true;
      this.isFormulaOpen = true;
    },
    handleFormulaClose() {
      this.isEditing = false;
      this.isAdding = false;
      this.isFormulaOpen = false;
      this.selectedFormula = null;
      this.selectedFormulaIndex = null;
    },
    handleSubmitFormula(data) {
      const { icdWordsCsv, icdWords } = data;
      const icdWordsConcat = icdWords
        .filter(e => !e.isDeleted)
        .map((e, i) => (i > 0 ? ` ${e.value}` : e.value))
        .join(",");
      if (icdWordsCsv != icdWordsConcat) {
        data.icdWordsCsv = icdWordsConcat;
      }
      if (this.isEditing) {
        const newFormulas = this.icdRule.icdFormulas.map(rule => {
          if (rule.id === data.id) {
            return data;
          }
          return rule;
        });
        this.icdRule.icdFormulas = newFormulas;

        this.isEditing = false;
      } else {
        if (data.sortOrder === null) {
          data.sortOrder = this.icdRule.icdFormulas.reduce((acc, curr) => {
            if (!curr.isDeleted && curr.sortOrder > acc) {
              return curr.sortOrder + 1;
            } else {
              return acc + 1;
            }
          }, 0);
        }
        data.id = Date.now();
        data.isNew = true;
        this.isAdding = false;
        this.icdRule.icdFormulas.push(data);
      }
      this.handleFormulaClose();
    }
  },
  watch: {
    icdRuleId: {
      immediate: true,
      handler(nv, ov) {
        if (nv != null && nv != ov) {
          IcdRulesApi.getIcdRuleById(nv).then(icdRule => {
            return (this.icdRule = { ...icdRule });
          });
        } else {
          this.icdRule = { ...this.defaultICDRule };
        }
      }
    }
  },
  computed: {
    icdFormulas() {
      if (this.icdRule?.icdFormulas?.length) {
        return this.icdRule.icdFormulas.filter(e => !e.isDeleted);
      }
      return [];
    },
    validatorMsgMap() {
      return validatorMsgMapBase;
    }
  }
};
</script>

<style lang="scss" scoped>
label input {
  position: absolute;
  left: -9999px;
}
.ck-button {
  display: block;
  position: relative;
  overflow: hidden;
}
.fieldset {
  max-height: 200px;
}
::v-deep nput[type="radio"] {
  &:hover,
  &:focus {
    outline: 2px auto $secondary;
    outline-offset: -2px;
  }
}
</style>
